import ScrollReveal from 'scrollreveal'

window.scrollReveal = ScrollReveal()
const reveal = {
    delay    : 200,
    distance : '40px',
    easing   : 'ease-in-out',
    scale    : 1,
    interval : 400
};

window.scrollReveal.reveal('.block__creator', reveal);
