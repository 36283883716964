import { __ } from '@wordpress/i18n';

window.addEventListener('DOMContentLoaded', () => {
    setTimeout( () => {
        window.addEventListener('click', () => {
            const creationsFilter = document.getElementsByClassName('creations-filter')[0];
    
            if (creationsFilter) {
                const dropdownOptions = creationsFilter.getElementsByClassName('fs-options')[0];
                const dropdownOption = creationsFilter.getElementsByClassName('fs-option');
                
                if (dropdownOptions) {
                    dropdownOptions.style.overflow = 'hidden';
                }
    
                for(var i = 0; i < dropdownOption.length; i++) {
                    dropdownOption[i].style.overflow = 'hidden';
                    dropdownOption[i].classList.add('animate__animated');
                    dropdownOption[i].classList.add('animate__slideInUp');
                    dropdownOption[i].style.setProperty('--animate-duration', '0.3s');
                }
            }   
        })
    
        const fsLabel = document.getElementsByClassName('fs-label');

        window.addEventListener('click', () => {
            for(var i = 0; i < fsLabel.length; i++) {
                fsLabel[i].innerHTML = __('Filter artists', 'expedition');
            }
        })
    }, 250)
});