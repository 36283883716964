/** Styles */
import '../scss/app.scss';
import 'phosphor-icons/src/index.js';

var $ = jQuery.noConflict();

/* Bootstrap vendor */
import 'bootstrap/dist/js/bootstrap.bundle.js';
import "./components/bs5lightbox";

import "./components/general";
import "./components/slider";
import "./components/header";
import "./components/countdown";
import "./components/facetwp";
import "./components/modal-add";
import "./components/modal-drop";
import "./components/animations";
import "./components/scrollama";

