window.addEventListener('DOMContentLoaded', () => {
    setTimeout ( () => {
        let formDrop = document.getElementById('gform_2');
        let dropLoader = document.getElementsByClassName('dropLoader')[0];
        if (formDrop) {
            let required = formDrop.getElementsByClassName('gfield_required');

            for (i = 0; i < required.length; i++) {
                required[i].style.display = 'none';
            }
        }

        if (dropLoader) {
            var percentage = dropLoader.querySelector('p');
            var uploading_content = dropLoader.querySelectorAll('p')[2];
            var fullCircle = dropLoader.querySelector('.full-circle');
        }

        jQuery(document).on('gform_confirmation_loaded', function(event, formId){
            let i = 0;
            dropLoader.classList.remove('d-none');

            function myDropLoop() {
                setTimeout(() => {
                    percentage.innerHTML = i.toString() + '';
                    i += Math.floor((Math.random() * 10) + 1);;
                    if (i <= 100) {
                        myDropLoop();
                    }
                    else {
                        percentage.innerHTML = '100';
                        uploading_content.innerHTML = 'Drop complete! <br>You\'ll hear from us soon';
                        fullCircle.classList.add('slide-up');
                    }
                }, 75)
            }
            myDropLoop();
        });
    }, 100);
});
