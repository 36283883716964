import Swiper, { Navigation, Pagination } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

// import Swiper styles
import 'swiper/swiper.scss';

const imageSlider = new Swiper('.image-swiper', {
    slidesPerView: 1,
    spaceBetween: 32,
    loop: true,
    autoplayStopOnLast: true,
    breakpoints: {
        768: {
            slidesPerView: 3,
        },
    },
    navigation: {
        nextEl: '.swiper-button-next',
    },
    pagination: {
        el: '.swiper-pagination',
    },
    on: {
        reachBeginning: function () {
            console.log('firstSlide');
          },
        reachEnd: function () {
            console.log('lastSlide');
          },
    }
});

const albumSlider = new Swiper('.album-swiper', {
    slidesPerView: 1,
    spaceBetween: 32,
    loop: false,
    autoplayStopOnLast: true,
    centeredSlides: true,
    breakpoints: {
        768: {
            slidesPerView: 2,
        },
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
    },
    on: {
        reachBeginning: function () {
            console.log('firstSlide');
          },
        reachEnd: function () {
            console.log('lastSlide');
          },
    }
});

const hubSlider = new Swiper('.hub-swiper', {
    slidesPerView: 1,
    spaceBetween: 32,
    loop: true,
    autoplayStopOnLast: true,
    breakpoints: {
        768: {
            slidesPerView: 3,
        },
    },
    navigation: {
        nextEl: '.swiper-button-next',
    }
});
