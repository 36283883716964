let countdownDiv = document.querySelectorAll('.block__release--countdown');
let classWalker = 0;
const maxClassWalker = countdownDiv.length;

var x = setInterval(() => {
    countdownDiv.forEach((countdownDiv) => {
        countdownDate = countdownDiv.dataset.date;
        countdown = {};
        countdown.year = countdownDate.slice(0, 4);
        countdown.month = parseInt(countdownDate.slice(5, 7)) - 1;
        countdown.day = countdownDate.slice(8, 10);
        countdown.hour = countdownDate.slice(11, 13);
        countdown.minute = countdownDate.slice(14, 16);

        let endDate = new Date(countdown.year, countdown.month, countdown.day, countdown.hour, countdown.minute);
        let startDate = new Date();
        let distance = endDate - startDate;
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (days.toString().length <= 1) {
            days = '0' + days;
        }
        if (hours.toString().length <= 1) {
            hours = '0' + hours;
        }
        if (minutes.toString().length <= 1) {
            minutes = '0' + minutes;
        }
        if (seconds.toString().length <= 1) {
            seconds = '0' + seconds;
        }

        if (distance > 0) {
            document.querySelectorAll('.time-spacer').forEach((item) => {
                item.classList.toggle('d-none');
            });
            document.getElementsByClassName('featured-release')[0].classList.add('d-none');
            document.getElementsByClassName("days")[classWalker].innerHTML = days;
            document.getElementsByClassName("hours")[classWalker].innerHTML = hours;
            document.getElementsByClassName("minutes")[classWalker].innerHTML = minutes;

            if (days.toString().length >= 3) {
                if (window.innerWidth < 768) {
                    document.getElementsByClassName("days")[classWalker].style.fontSize = "48px";
                    document.getElementsByClassName("hours")[classWalker].style.fontSize = "48px";
                    document.getElementsByClassName("minutes")[classWalker].style.fontSize = "48px";
                }
                else {
                    document.getElementsByClassName("days")[classWalker].style.fontSize = "64px";
                    document.getElementsByClassName("hours")[classWalker].style.fontSize = "64px";
                    document.getElementsByClassName("minutes")[classWalker].style.fontSize = "64px";
                }
            }

            dates = [days, hours, minutes, seconds];
            plurals = ['days-plural', 'hours-plural', 'minutes-plural', 'seconds-plural'];
            singulars = ['days-singular', 'hours-singular', 'minutes-singular', 'seconds-singular'];

            for (i in dates) {
                if (dates[i] == 1) {
                    document.getElementsByClassName(plurals[i])[classWalker].classList.add('d-none');
                    document.getElementsByClassName(singulars[i])[classWalker].classList.remove('d-none');
                }
                else {
                    document.getElementsByClassName(plurals[i])[classWalker].classList.remove('d-none');
                    document.getElementsByClassName(singulars[i])[classWalker].classList.add('d-none');
                }
            }

            if (days <= 0) {
                document.getElementsByClassName("days")[classWalker].innerHTML = hours;
                document.getElementsByClassName("hours")[classWalker].innerHTML = minutes;
                document.getElementsByClassName("minutes")[classWalker].innerHTML = seconds;

                document.getElementsByClassName("days-text")[classWalker].classList.add("d-none");
                document.getElementsByClassName("days-spacer")[classWalker].classList.add("d-none");
                document.getElementsByClassName("seconds-text")[classWalker].classList.remove("d-none");
                document.getElementsByClassName("seconds-spacer")[classWalker].classList.remove("d-none");
            }
        }
        else if (distance <= 0) {
            let countdownPreorder = document.getElementsByClassName("preorder")[classWalker];
            let countdownStreamable = document.getElementsByClassName('streamable')[classWalker];
            let preorderBtn = document.getElementsByClassName('preorder-btn')[classWalker];
            let streamBtn = document.getElementsByClassName('stream-btn')[classWalker]

            countdownPreorder.classList.add('d-none');
            countdownStreamable.classList.remove('d-none');
            preorderBtn.classList.add('d-none');
            streamBtn.classList.remove('d-none');
        }

        if (classWalker < maxClassWalker - 1 ) {
            classWalker += 1;
        }
    });
}, 1000);
