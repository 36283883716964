jQuery(document).ready(function ($) {
    /**
     * @name gform button disabled
     * @description Control the gform submit button
     */

    $('form[id^="gform_"]').on('change', function (e) {
        var $reqd = $(this).find('.gfield_contains_required.gfield_visibility_visible').filter(function (i, c) {
            return []
                .concat($(c).find('input[type="text"], textarea').filter(function (i, fl) { return $(fl).val().length == 0; }).get())
                .concat($(c).find('input[type="checkbox"]').not(':checked').get())
                .length;
        });
        if ($reqd.length) {
            $(this).find('input[type="submit"]').addClass('disabled button-disabled').attr('disabled', 'disabled');
        } else {
            $(this).find('input[type="submit"]').removeClass('disabled button-disabled').removeAttr('disabled');
        }
    }).trigger('change');

    /**
     * @name Header js settings
     * @description Control the header fade in effect
     */

    $(function() {
        const headerDesktop = $(".header.--basic");
        const navDesktop = $(".nav-main");
        const headerDesktopCreator = $(".header.--creator");
        const headerMobile = $("#header-mobile");

        $(window).scroll(function() {
            var scroll = $(window).scrollTop();

            if (scroll >= 80) {
                navDesktop.addClass('active');
                headerDesktopCreator.addClass('bg-white animate__animated animate__fadeInDown');
                headerMobile.addClass('fixed-top animate__animated animate__fadeInDown');
            } else {
                navDesktop.removeClass('active');
                headerDesktopCreator.removeClass('bg-white animate__animated animate__fadeInDown');
                headerMobile.removeClass('fixed-top animate__animated animate__fadeInDown');
            };
        });
    });

    /**
     * @name Scrollto
     * @description Scroll to anchor
     * @param {string} scroll
     */

    function scrollToAnchor(scroll){
        const divTag = $("div[name='"+ scroll +"']");
        $('html,body').animate({scrollTop: divTag.offset().top},'slow');
    }

    $('.scroll').on('click', function(){
        const scroll = $(this).data('scroll');
        scrollToAnchor(scroll);
    });

    /**
     * @name Mobile menu
     */

    const open = $('.navbar-toggler');
    const mobilemenu = $('#mobile-menu-container');

    $(open).on('click', function(){
        $(open).toggleClass('active');
        $(mobilemenu).toggleClass('active');
        $('.brand-logo').toggleClass('animate__rotateIn');
    });

    /**
     * @name Events
     * @description Events Block
     */

    const showMoreEventsBtn = $('#js-get-events');

    $('.allevents').hide();

    $(showMoreEventsBtn).on('click', function(){
        $(this).toggleClass('active');
        $('.allevents').slideToggle();
    });
});
