window.addEventListener('DOMContentLoaded', () => {
    setTimeout ( () => {
        let formAdd = document.getElementById('gform_1');
        let modal = document.getElementById('addModal');

        if (modal) {
            let modalLoader = modal.getElementsByClassName('modal-loader')[0];

            if (modalLoader) {
                let percentage = modalLoader.querySelector('p');
                let uploading_content = modalLoader.querySelectorAll('p')[2];
            }
        }
        if (formAdd) {
            let required = formAdd.getElementsByClassName('gfield_required');

            for (i = 0; i < required.length; i++) {
                required[i].style.display = 'none';
            }
        }

        jQuery(document).on('gform_confirmation_loaded', function(event, formId){
            let i = 0

            function myLoop() {
                setTimeout(() => {
                    percentage.innerHTML = i.toString() + '';
                    i += Math.floor((Math.random() * 10) + 1);;
                    if (i <= 100) {
                        myLoop();
                    }
                    else {
                        percentage.innerHTML = '100';
                        uploading_content.innerHTML = 'Upload succesful';
                        modalLoader.classList.add('fadeOut');
                    }
                }, 75)
            }
            modalLoader.classList.remove('fadeOut');
            modalLoader.classList.remove('d-none');
            myLoop();
        });
    }, 100);
});

