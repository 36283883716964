/**
 * @name Scrollama
 * @description Slide divs based on viewport
 */

const scrollama = require("scrollama");

const scrollerSupport = scrollama();
const scrollerReleaseRight = scrollama();
const scrollerReleaseLeft = scrollama();
const scrollerAdd = scrollama();
const scrollerHub = scrollama();

const support = document.getElementsByClassName('block__support');
const release = document.getElementsByClassName('block__release');
const add = document.getElementsByClassName('block__add');
const hub = document.getElementsByClassName('block__hub');

function hubStepEnter(response) {
    response.element.classList.toggle('hub-scroll');
}

function hubStepExit(response) {
    response.element.classList.toggle('hub-scroll');
}

if (hub.length >= 1) {
    scrollerHub.setup({
        step: ".block__hub",
    })
    .onStepEnter(hubStepEnter)
    .onStepExit(hubStepExit);
}

if (support.length >= 1) {
    scrollerSupport.setup({
        step: ".block__support"
    }).onStepEnter((response) => {
        response.element
            .querySelector(".js-animated-down")
            .classList.add("js-animated-down--slide-down");
    }).onStepExit((response) => {
        response.element
            .querySelector(".js-animated-down")
            .classList.remove("js-animated-down--slide-down");
    });
}

if (release.length >= 1) {
    scrollerReleaseRight.setup({
        step: ".block__release"
    }).onStepEnter((response) => {
        response.element
            .querySelector(".js-animated-right")
            .classList.add("js-animated-right--slide-right");
    }).onStepExit((response) => {
        response.element
            .querySelector(".js-animated-right")
            .classList.remove("js-animated-right--slide-right");
    });

    scrollerReleaseLeft.setup({
        step: ".block__release"
    }).onStepEnter((response) => {
        response.element
            .querySelector(".js-animated-left")
            .classList.add("js-animated-left--slide-left");
    }).onStepExit((response) => {
        response.element
            .querySelector(".js-animated-left")
            .classList.remove("js-animated-left--slide-left");
    });

    scrollerAdd.setup({
        step: ".block__add"
    }).onStepEnter((response) => {
        response.element
            .querySelector("#rect-animate")
            .setAttribute("values", "0;159.205");
        response.element.querySelector('#rect-animate').beginElement();
    }).onStepExit((response) => {
        response.element
            .querySelector("#rect-animate")
            .setAttribute("values", "159.205;0");
        response.element.querySelector('#rect-animate').beginElement();
    });
}
